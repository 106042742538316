<template>
  <div class="about-egypt-page">
    <div class="container">
      <img
      class="intro-img-in mb-2"
      :src="section.media_url"      />
      <h2 class="fw-bold" :class="dir_ar">{{ section.titles ? section.titles[lang] : section.title }}</h2>
      <div
        :class="['artical', dir_ar, 'my-2']"
        v-html="section.descriptions?section.descriptions[lang]:section.description"
      ></div>
      <button
      type="button"
      v-if="section.custom_fields.action_target && section.custom_fields.action_text&& section.custom_fields.action_text[lang]"
      :class="['btn-pri', dir_ar == 'dir-rtl' ? 'ms-auto' : 'me-auto']"
      @click="openTarget(section.custom_fields)"
    >
      {{ section.custom_fields.action_text[lang] }}
    </button>
    </div>
  </div>
</template>

<script>
import PageTitle from "../components/misc/PageTitle.vue";
export default {
  components: {
    PageTitle,
  },
  props: ["section"],
};
</script>

<style>
</style>