import Axios from "./embassyInc";

export default {
    state: {
        service: {},
        services: [],
    },
    mutations: {
        setServices(state, newsPayload) {
            state.services = newsPayload;
        },
        setService(state, vNewsPayload) {
            state.service = vNewsPayload;
        }
    },
    getters: {
        services(state) {
            return state.services;
        },
        service(state) {
            return state.service;
        }
    },
    actions: {
        getServices({ commit },payload={}) {
            commit('setDataLoading', true);
            return new Promise((resolve, reject) => {
                Axios.post("/v1/website/services", { page: payload.page, status: 1 }).then(res => {
                    if (res.data.results != null) {
                        commit("setServices", res.data.results.data);
                        commit('setDataLoading', false);
                        resolve(res);
                    } else {
                        commit('setDataLoading', false);
                        reject(res);
                    }
                }).catch(err => {
                    commit('setDataLoading', false);
                    reject(err);
                });
            });
        },
        filterServices({ commit }, payload) {
            commit('setDataLoading', true);
            return new Promise((resolve, reject) => {
                Axios.post("/v1/website/services", { page: payload.page }).then(res => {
                    if (res.data.results != null) {
                        commit("setServices", res.data.results.data);
                        commit('setDataLoading', false);
                        resolve(res);
                    } else {
                        commit('setDataLoading', false);
                        reject(res);
                    }
                }).catch(err => {
                    commit('setDataLoading', false);
                    reject(err);
                });
            });
        },
        loadVService({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post(`/v1/website/services/show/${payload.id}`).then(res => {
                    if (res.data.results != null) {
                        commit("setService", payload.id);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        }
    }
};