import Axios from "./embassyInc";

export default {
  state: {
    news: [],
    viewPage: [],
    allNews: [],
    // category: {},
    // categories: []
  },
  mutations: {
    setNews(state, newsPayload) {
      state.news = [...(newsPayload||[])];
    },
    setAllNews(state, newsPayload) {
      state.allNews = newsPayload;
    },
    setVNews(state, vNewsPayload) {
      state.viewPage = vNewsPayload;
    },

    // setCategory(state, payload) {
    //     state.category = payload;
    // },
    // setCategoriess(state, payload) {
    //     state.categories = payload;
    // },
  },
  getters: {
    getNews(state) {
      return state.news;
    },
    getAllNews(state) {
      return state.allNews;
    },
    getVNews(state) {
      return state.viewPage;
    },
    // category: (state) => state.category,
    // categories: (state) => state.categories,
  },

  actions: {
    loadFilterdNews({ commit }, payload = {}) {
      commit("setDataLoading", true);
      return new Promise((resolve, reject) => {
        Axios.post("/v1/website/news", { ...payload, status: 1 })
          .then((res) => {
            if (res.data.results != null) {
              commit("setDataLoading", false);
              resolve(res);
              commit("setNews", res.data.results.data);
            } else {
              commit("setDataLoading", false);
              reject(res);
            }
          })
          .catch((err) => {
            commit("setDataLoading", false);
            reject(err);
          });
      });
    },
    loadNews({ commit }, payload = {}) {
      commit("setDataLoading", true);
      return new Promise((resolve, reject) => {
        Axios.post("/v1/website/news", {
          page: payload.page,
          status: 1,
        })
          .then((res) => {
            if (res.data.results != null) {
              commit("setDataLoading", false);
              resolve(res);
              commit("setAllNews", res.data.results.data);
            } else {
              commit("setDataLoading", false);
              reject(res);
            }
          })
          .catch((err) => {
            commit("setDataLoading", false);
            reject(err);
          });
      });
    },
    loadVNews({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/v1/website/news/show/${payload.id}`)
          .then((res) => {
            if (res.data.results != null) {
              commit("setVNews", res.data.results);
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
