var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"lastnews-page"},[_c('div',{class:['container', _vm.dir_ar]},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',[(!_vm.section.custom_fields.view_all)?_c('h3',{staticClass:"header-main"},[_vm._v(" "+_vm._s(_vm.section.title)+" "),_c('span',{staticClass:"header-divider d-inline-block mx-1 mt-1"})]):_vm._e(),(_vm.section.description && !_vm.section.custom_fields.view_all)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.section.description)}}):_vm._e()]),(
          _vm.section.custom_fields.action_target &&
          _vm.section.custom_fields.action_text &&
          _vm.section.custom_fields.action_text[_vm.lang]
        )?_c('div',{class:['more-btn', _vm.dir_ar == 'dir-rtl' ? 'me-auto' : 'ms-auto']},[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.openTarget(_vm.section.custom_fields)}}},[_vm._v(" "+_vm._s(_vm.section.custom_fields.action_text[_vm.lang] || _vm.$t("show all"))+" ")])]):_vm._e()]),(!_vm.loading)?_c('div',{staticClass:"news mt-3"},_vm._l((_vm.newsList),function(news,index){return _c('NewsCard',{key:index,attrs:{"news":news},on:{"readMore":function($event){_vm.readMore(
            news.id,
            news.titles && news.titles[_vm.lang]
              ? news.titles[_vm.lang].split(' ').join('-')
              : news.title.split(' ').join('-')
          )}}})}),1):(_vm.loading)?_c('div',{staticClass:"container my-5"},[_c('div',{staticClass:"loader mx-auto my-5"})]):_vm._e()]),(this.section.custom_fields.view_all)?_c('div',{class:['container', _vm.dir_ar]},[_c('Pagination',{attrs:{"records":_vm.rows,"per-page":10,"options":_vm.paginationOptions},on:{"paginate":_vm.getPageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }