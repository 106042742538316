<template>
  <div>
    
    <section class="hero-section-container">
      <HeroSection />
    </section>
    
    <!-- <section class="services-section-container">
      <Services />
    </section> -->
    
    <section class="services-section-container">
      <div class="hero-container mt-4" :class="[t_ar]">
        <div class="news-holder">
          <div class="container">
            <newsSwiper />
          </div>
        </div>
      </div>
    </section>
    

    <section class="trending-section-container app-sizing">

      <TrendingServices />
    </section>

    <section class="aboutEmbassy-section-container app-sizing">

      <AboutEmbassy />
    </section>

    <section class="aboutEG-section-container app-sizing">

      <AboutEG />
    </section>

    <section class="news-section-container app-sizing">
      <LastNewsSection
        title="اخر الاخبار"
        :readMoreBtn="true"
        :lastNews="lastNews"
      />
    </section>

    <section class="contactUs-section-container">
      <ContactUs :useTitle="true" />
    </section>
  </div>
</template>

<script>

// @ is an alias to /src
import ContactUs from "@/components/ContactUs";
import HeroSection from "@/components/HeroSection";
import newsSwiper from "@/components/newsSwiper";
import TrendingServices from "@/components/TrendingServices";
import AboutEmbassy from "@/components/AboutEmbassy";
import AboutEG from "@/components/AboutEG";
import LastNewsSection from "@/components/LastNews";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    ContactUs,
    HeroSection,
    newsSwiper,
    LastNewsSection,
    TrendingServices,
    AboutEmbassy,
    AboutEG,
  },
  computed: {
    ...mapGetters(["lastNews", 'controlledRoutes']),
  },
};
</script>
