<template>
  <div
    class="trending-card border p-3 col-lg-3 col-md-4 col-sm-6 col-12 my-10 mx-auto"
    v-if="card.status"
    @click="serviceReservation"
  >
  <div class="d-flex flex-column justify-content-between h-100">
    <div>
  <div class="d-flex align-items-center justify-content-center" style="min-height: 250px;">
    <img :src=" card.image_url||placeholder" width="auto" style="max-height: 250px;" alt="Service image">
  </div>

    <h5 class="trending-text" :class="lang">
      {{ card.title }}
    </h5>
    <p v-if="card.description && card.description!='null'">
      {{ card.description }}
    </p>

    </div>
    <button
    class="btn-sec pointer"
    type="button"
    @click="serviceReservation"
    >
    {{ $t("bookService") }}
  </button>
</div>
  </div>
</template>

<script>
export default {
  props: ["card", "isActive", "elid"],
  methods: {
    activeCard() {
      this.$emit("activeCard");
    },
    serviceReservation() {
      this.$emit("reservation");
    },
  },
};
</script>
