<template>
  <div>
    <div class="main-section" v-if="!loading">
      <div class="main-slider-container container">
        <Swiper
          ref="newsSwiperSlider"
          class="swiper col-12 py-1"
          :options="swiperOptions"
          :dir="ar ? 'rtl' : 'ltr'"
          v-if="swiperStart"
        >
          <SwiperSlide class="" v-for="card in banners.filter(el => el.status).sort((a,b)=> a.ord-b.ord)" :key="card.id">
            <div  class="hero-section-card row align-items-center">
              <div class="col-12 col-lg-auto image-side" v-if="card.image_url">
                <img class="hero-cover" :src="card.image_url" :alt="card.title">
              </div>
              <div class="col-12 mt-3 mt-lg-0" :class="card.image_url?'col-lg-6':''">
                <h5 class="m-0">
                  {{ card.title }}
                </h5>
                <p class="m-0" v-html="card.description"></p>
                <button
                  class="btn-pri-sm"
                  v-if="card.target && card.target.target && card.target.button&& card.target.button[lang]"
                  @click="openTarget(card.target)"
                >
                  {{ card.target.button[lang] }}
                </button>
              </div>
            </div>
            <!-- <div
              class="slider-card"
              :style="`background-image:url(${card.image_url})`"
            >
              <div class="overlay" :class="lang"></div>
              <div
                :class="[
                  'slider-card-text  mb-3',
                  dir_ar == 'dir-rtl' ? 'text-end' : 'text-start',
                ]"
              >
          
              </div>
            </div> -->
          </SwiperSlide>
        </Swiper>
        <div class="hero-swiper-pagination swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>
import newsSwiper from "@/components/newsSwiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    newsSwiper,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      btnText: "",
      swiperStart: false,
      banners: [],
      swiperOptions: {
        speed: 900,
        slidesPerView: 1,
        spaceBetween: 10,
        loop: false,

        autoplay: {
          delay: 7000,
          pauseOnMouseEnter: true,
          // reverseDirection: true,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".hero-swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      },
      swiper: null,
      loading: false,
      sliderData: null
    };
  },
  watch: {},
  props: ["section"],
  mounted() {
    this.loading = true;
    if (this.section.custom_fields.slider) {
      this.loadVSlider({
        id: this.section.custom_fields.slider.id,
      })
        .then((res) => {
          this.loading = false;
          setTimeout(() => {
            this.swiperStart = true;
            this.sliderData = res.data.results;
            this.banners = res.data.results.banners;
          }, 100);
        })
        .catch((err) => {
          this.loading = false;
        });
    }
  },
  methods: {
    ...mapActions(["loadSliders", "loadBanners", "loadVSlider"]),
  },
  computed: {
    ...mapGetters(["controlledRoutes"]),
  },
};
</script>