<template>
  <div :class="[dir_ar, 'app-footer','mt-auto']">
    <footer class="appfooter-container">
      <div class="container pt-3 text-center text-white">
        <div
          class="
            fw-cold
        flex-wrap
            en
            mb-2
            pt-2
            align-items-center
            justify-content-center
            d-block d-md-flex
          "
          v-if="$route.name !== 'Home'"
        >
          <div>
            {{ address }}
          </div>
          <div class="mx-4" dir="ltr">
            {{ phone  ? `${phone}`.replace(/(\d{2})(\d{2})/, "$1 $2  ") : phone }}
          </div>
          <div>
            {{ email }}
          </div>
        </div>
        <p class="en mb-0">
          Powerd by:
          <a
            href="https://digitaleka.de"
            target="_blank"
            class="no-link-style text-white text-underline fw-bold"
            >Digitaleka GmbH
          </a>
        </p>
        <p class="text-white">
          {{ $t("All rights reserved to") }}{{ appName }}
        </p>
        <!-- <div class="row justify-content-between my-3">
          <div class="app-logo">
            <img :src="logo" @click="$router.push('/')" />
            <p class="desc" @click="$router.push('/')">
              {{ appName }}
            </p>
            <div
              :class="[
                'to-top-container',
                dir_ar == 'dir-rtl' ? 'me-auto' : 'ms-auto',
              ]"
            >
              <button
                type="button"
                :class="[
                  'btn-icon-top',
                  dir_ar == 'dir-rtl' ? 'me-auto' : 'ms-auto',
                  'd-block',
                ]"
                @click="goToTop"
              >
                <i class="fa fa-long-arrow-up text-pri" />
              </button>
            </div>
          </div>
        </div>

        <div class="row my-3">
          <div class="col">
            <div class="footer-info my-4" v-html="desc"></div>
            <div class="footer-social-container d-flex d-lg-flex d-none">
              <div class="" v-for="social in socials" :key="social.id">
                <button
                  type="button"
                  class="btn-icon-social"
                  v-if="social.name != 'baseURL'"
                >
                  <a :href="social.link" target="_blank"
                    ><i class="fa" :class="social.icon"
                  /></a>
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4 p-0">
            <div class="appfooter-links-container">
              <ul class="appfooter-items-container">
                <li
                  class="appfooter-item-container"
                  v-for="(item, index) in appheaderLinkscol1"
                  :key="index"
                  @click="goToSection(item)"
                >
                  <div class="appfooter-link">
                    {{ item.label }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-12 col-lg-4 my-2 p-0">
            <div class="contacts">
              <a :href="'tel:+' + phone" class="contact-text" dir="ltr">
                +{{ phone }}
              </a>
              <a
                :href="'mailto:' + email"
                class="d-block contact-text"
                v-if="email"
                >{{ email }}</a
              >
              <a
                href="https://goo.gl/maps/2zAkosLFAyDMd8iy9"
                class="contact-text"
                >{{ address }}</a
              >
            </div>
            <div class="footer-social-container d-flex d-lg-none">
              <div class="" v-for="social in socials" :key="social.id">
                <button
                  type="button"
                  class="btn-icon-social"
                  v-if="social.name != 'baseURL'"
                >
                  <i class="fa" :class="social.icon" />
                </button>
              </div>
            </div>
          </div>
        </div>-->
      </div>
    </footer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      email: "",
      subEmail: "",
      subSuc: "",
      subErr: "",
      desc: this.$t("default description"),
      appName: this.$t("appName"),
      phone: "+49304775470",
      address: "Stauffenbergstr. 6-7 10785 Berlin",
      logo: require("../../assets/images/logo/logo.png"),
      appheaderLinkscol1: [
        { id: 1, label: this.$t("home"), link: "Home" },
        { id: 2, label: this.$t("allServices"), link: "Services" },
        { id: 3, label: this.$t("About egypt"), link: "About Egypt" },
        { id: 4, label: this.$t("aboutEmbassy"), link: "AboutEmbassy" },
        { id: 5, label: this.$t("latest News"), link: "Last-News" },
        { id: 5, label: this.$t("contactUs"), link: "ContactUs" },
      ],
      socials: [
        { id: 1, icon: "fa-twitter", link: "" },
        { id: 2, icon: "fa-instagram", link: "" },
        { id: 3, icon: "fa-facebook", link: "" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getSettings"]),
  },
  methods: {
    ...mapActions(["subscribe"]),
    subAction() {
      this.subscribe(this.subEmail)
        .then((res) => {
          if (res.status == 200) {
            this.subErr = "";
            this.subSuc = "تحقق من صندوق البريد الخاص بك لتأكيد الاشتراك";
            setTimeout(() => {
              this.subSuc = "";
            }, 5000);
          }
        })
        .catch((err) => {
          if (err.response.status == 422) {
            this.subSuc = "";
            this.subErr = "برجاء ادخال بريدك الالكتروني ";
            setTimeout(() => {
              this.subErr = "";
            }, 55000);
          }
        });
    },
    goToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    goToSection(item) {
      this.$router.push({ name: item.link });
    },
  },
  watch: {
    getSettings(nsett) {
      if (nsett) {
        this.phone = nsett.phone;
        this.address = nsett.address;
        this.email = nsett.email;
        this.logo = nsett.logo
          ? nsett.logo
          : require("../../assets/images/logo/logo.png");
        this.appName = nsett.AppName ? nsett.AppName : this.$t("appName");
        this.desc = nsett.site_meta_description;
      }
    },
  },
  mounted() {
    if (this.getSettings) {
      this.phone = this.getSettings.phone;
      this.address = this.getSettings.address;
      this.email = this.getSettings.email;
      this.logo = this.getSettings.logo
        ? this.getSettings.logo
        : require("../../assets/images/logo/logo.png");
      this.appName = this.getSettings.AppName
        ? this.getSettings.AppName
        : this.$t("appName");
      this.desc = this.getSettings.site_meta_description;
    }
  },
};
</script>
