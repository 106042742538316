import Axios from "./embassyInc";

export default {
  state: {
    pages: null,
    page: null,
    dataLoading: false,
  },
  mutations: {
    setpages(state, pagesPayload) {
      state.pages = pagesPayload;
    },
    setVPage(state, vPagePayload) {
      state.page = vPagePayload;
    },
    setDataLoading(state, payload) {
      state.dataLoading = payload;
    },
  },
  getters: {
    page: (state) => state.page,
    getpages(state) {
      return state.pages;
    },
    dataLoading(state) {
      return state.dataLoading;
    },
  },
  actions: {
    loadPages({ commit }) {
      commit("setDataLoading", true);
      return new Promise((resolve, reject) => {
        Axios.post("/v1/website/pages",{
          status: 1
        })
          .then((res) => {
            if (res.data.results != null) {
              commit("setpages", res.data.results.data);
              commit("setDataLoading", false);
              resolve(res);
            } else {
              commit("setDataLoading", false);
              reject(res);
            }
          })
          .catch((err) => {
            commit("setDataLoading", false);
            reject(err);
          });
      });
    },
    loadVPage({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        const isLoaded = state.pages.find((page) => page.id === payload.id);
        if (isLoaded) {
          commit("setVPage", isLoaded);
          resolve(isLoaded);
        } else {
          Axios.post(`/v1/website/pages/show/${payload.id}`)
            .then((res) => {
              if (res.data.results != null) {
                commit("setVPage", res.data.results);
                resolve(res);
              } else {
                reject(res);
              }
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    },
  },
};
