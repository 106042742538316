<template>
  <div
    class="news-container d-flex justify-content-center"
    v-if="!loading && banners && banners.length != 0"
  >
    <div class="col-12">
      <div class="row">
        <Swiper ref="mySwiper" class="swiper col-12 p-1 py-3" :options="swiperOptions">
          <SwiperSlide
            class="col"
            v-for="card in banners
              .filter((el) => el.status)
              .sort((a, b) => a.ord - b.ord)"
            :key="card.id"
            @click.native="openTarget(card.target)"
          >
            <div
              :class="[card.image_url ? 'news-card' : 'news-card-placeholder', lang]"
              :style="`background-image:url(${card.image_url || placeholder})`"
            >
              <div class="overlay"></div>
              <h5 class="news-card-text">
                {{ card.title }}
              </h5>
              <p class="news-card-text" v-html="card.description"></p>
            </div>
          </SwiperSlide>
        </Swiper>
        <div class="news-swiper-pagination swiper-pagination"></div>
      </div>
    </div>
  </div>
  <section-loader v-else-if="loading" />
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { mapActions, mapGetters } from "vuex";
// import "swiper/swiper-bundle.css";
import SectionLoader from "./misc/SectionLoader.vue";
export default {
  props: ["section"],
  components: {
    Swiper,
    SwiperSlide,
    SectionLoader,
  },
  data() {
    return {
      swiper: null,
      loading: true,
      banners: [],
    };
  },
  watch: {},
  mounted() {
    if(this.section.custom_fields.slider){
      this.loadVSlider({ id: this.section.custom_fields.slider.id }).then((res) => {
        this.banners = res.data.results.banners;
        this.loading = false;
      }).catch(err=>{
        this.loading = false;
      })
    }
  },
  methods: {
    ...mapActions(["loadVSlider", "loadNews"]),
    slideNext() {
      this.swiper.slideNext(900);
    },
    slidePrev() {
      this.swiper.slidePrev(900);
    },
  },
  computed: {
    ...mapGetters(["getVSlider"]),
    swiperOptions() {
      if (this.innerWidth >= 992) {
        return {
          autoplay: {
            delay: 7000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          },
          speed: 900,
          slidesPerView: 3,
          spaceBetween: 0,

          loop: false,
          grabCursor: true,
          centeredSlides: false,
          pagination: {
            el: ".news-swiper-pagination",
            clickable: true,
          },
        };
      } else if (this.innerWidth >= 768) {
        return {
          speed: 900,
          slidesPerView: "auto",
          spaceBetween: 1,
          loop: true,
          grabCursor: true,

          centeredSlides: false,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".news-swiper-pagination",
            clickable: true,
          },
        };
      } else {
        return {
          speed: 900,
          slidesPerView: "auto",
          spaceBetween: 10,
          loop: true,

          grabCursor: true,
          autoplay: {
            delay: 3000,
            pauseOnMouseEnter: true,
            // reverseDirection: true,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".news-swiper-pagination",
            clickable: true,
          },
          breakpoints: {
            992: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 1,
            },
          },
        };
      }
    },
  },
};
</script>
