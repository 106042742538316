<template>
  <div :class="['fixed-menu', dir_ar]">
    <div class="closer" @click="close">
      <i class="fa fa-times"></i>
    </div>
    <div class="row h-100">
      <div class="col-5 col-xl-3">
        <div class="links h-100">
          <ul>
            <li
              v-for="(link, i) in controlledRoutes.slice(0,7)"
              :key="i"
              :style="`height:calc(100% / ${controlledRoutes.length})`"
              @click="close"
              >
              <router-link :to="link.path">{{
                link.meta.names?link.meta.names[lang]:link.name
              }}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-7 col-xl-9 service">
        <h2>{{$t('mostReservedServices')}}</h2>

        <div class="row" v-if="!loader && services && services.length > 0">
          <div
            class="col-6"
            v-for="(card, i) in services"
            :key="i * card.id + 10"
            >
            <div class="my-5 d-flex align-items-center border-rounded"
            style="overflow: hidden;"
            :class="card.id == $route.params.id?'border border-primary':''"
            >
              <img
                :src="card.image_url||require('../../assets/images/menu.png')"
                alt="Icon"
                class="pointer"
                width="66"
                @click="serviceReservition(card.id)"
              />
              <p
                class="m-0 mx-3 pointer ser-title"
                @click="serviceReservition(card.id)"
              >
                {{ card.title }}
              </p>
            </div>
          </div>
        </div>
        <div class="loader" v-else-if="loader">

        </div>
        <ErrorMsg :message="err" v-else-if="err" />
      </div>
      <img
        src="../../assets/images/al-nisr.png"
        alt="EG Logo"
        class="nisr"
        width="50"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ErrorMsg from './ErrorMsg.vue';

export default {
    data() {
        return {
            links: [
                { id: 1, label: this.$t('home'), link: "Home" },
                { id: 2, label: this.$t('allServices'), link: "Services" },
                { id: 3, label: this.$t('About egypt'), link: "AboutEG" },
                { id: 4, label: this.$t('aboutEmbassy'), link: "AboutEmbassy" },
                { id: 5, label: this.$t('latest News'), link: "Last-News" },
                { id: 5, label: this.$t('contactUs'), link: "ContactUs" },
            ],
            err: null,
            loader: false
        };
    },
    methods: {
        ...mapActions(['getServices']),
        close() {
            this.$emit("close");
        },
        serviceReservition(id) {
            if (id == this.$route.params.id) {
                return false;
            }
            else {
                this.$router.push({ name: "serviceReservation", params: { id: id } });
            }
        },
    },
    computed: {
        ...mapGetters(['services','controlledRoutes']),
        serviceCards() {
            return this.$store.state.trendingCards;
        },
    },
    watch: {
        $route(newRoute) {
            if (newRoute) {
                this.close();
            }
        },
    },
    mounted() {
        if (!this.services || this.services.length == 0) {
            this.loader = true;
            this.getServices().then(res => {
                this.loader = false;
            }).catch(err => {
                this.loader = false;
                this.err = err && err.response ? err.response.data.results.message : err;
            });
        }
    },
    components: { ErrorMsg }
};
</script>

<style>
</style>