<template>
  <div
    :class="['article', 'my-5', lang, dir_ar]"
  >
  <h3 class="header-main d-flex align-items-center">
    {{ section.titles?section.titles[lang]:section.title }}
    <span class="header-divider d-inline-block mx-1 mt-1"></span>
  </h3>
  <img
    :src="section.media_url"
    alt="Article Image"
    :class="lang"
    v-if="section.media_url"
  />
    <p
      v-if="section.descriptions||section.description"
      v-html="section.descriptions?section.descriptions[lang]:section.description"
    ></p>
    <div class="clearfix"></div>
    <button
    type="button"
    v-if="section.custom_fields.action_target && section.custom_fields.action_text&& section.custom_fields.action_text[lang]"
    :class="['btn-pri', dir_ar == 'dir-rtl' ? 'ms-auto' : 'me-auto']"
    @click="openTarget(section.custom_fields)"
  >
    {{ section.custom_fields.action_text[lang] }}
  </button>
  </div>
</template>

<script>
export default {
  props: ["section"],
};
</script>