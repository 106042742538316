<template>
  <div :class="['last-news', dir_ar]">
    <div class="container">
      <div class="d-flex justify-content-between align-items-end">
        <div class="d-flex align-items-center">
          <h4 class="fw-bold">
            {{ title }}
            <span class="header-divider d-inline-block mx-1 mt-1"></span>
          </h4>
        </div>
        <div class="more-btn" v-if="readMoreBtn">
          <button class="btn" @click="lastNewsPage">الكل</button>
        </div>
      </div>
      <div class="news-cards my-5" v-if="lastNews && lastNews.length">
        <template v-for="(news, index) in lastNews">
          <NewsCard
            v-if="news.id != getVNews.id"
            :key="index"
            :news="news"
            @readMore="readMore(news)"
          />
        </template>
      </div>
      <p v-if="lastNews && lastNews.length == 0">
        {{ $t("noNewsFound") }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NewsCard from "./misc/NewsCard";
export default {
  props: ["lastNews", "title", "readMoreBtn"],
  components: {
    NewsCard,
  },
  computed: {
    ...mapGetters(["getVNews"]),
  },
  methods: {
    readMore(news) {
      // const newsSelected = this.newsList.find((news) => id === news.id);
      // alert(`selected ${newsSelected.id} - ${newsSelected.title}`);
      this.$router.push({
        name: "NewsArticle",
        params: { id: news.id, title: news.titles?news.titles[this.lang]:news.title },
      });
    },
    lastNewsPage() {
      this.$router.push({ name: "Last-News" });
    },
  },
};
</script>

<style>
</style>