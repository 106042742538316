<template>
    <div >
      <img width="100px" src="../../assets/images/x.png" alt="Page Not Found" />
      <h2 class="sec-title mt-4">{{ $t(message) }}</h2>
      <button class="btn-pri" @click="action">
        {{ $t('Back') }}
      </button>
    </div>
  </template>
  
  <script>
  export default {
    props:['message'],
    methods:{
      action(){
        this.$router.back()
      }
    }
  };
  </script>
  
  <style>
  </style>